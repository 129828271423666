import React, { FC } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Row, Select, Spin, DatePicker } from "antd"

import { defaultAnalyticsFilters } from "../../../common/constants/filters"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { removeObjectEmptyKeys } from "../../../utils/data"

interface Props {
    isLoading: boolean;
    sorting: { [x: string]: string }
    setFilters: (filters: any) => void;
}

const FilterTable: FC<Props> = ({ isLoading, setFilters, sorting }) => {
  const [ form ] = Form.useForm()
  const { filterList } = useTypedSelector((state) => state.stock)
  const { stockGetList } = useActions()

  const onSubmitFilter = (values: any) => {
    const cleanedValues = removeObjectEmptyKeys(values)
    const data = {
      ...cleanedValues,
      status_id: cleanedValues.status_id?.length ? cleanedValues.status_id : undefined,
      marketplace_id: cleanedValues.marketplace_id?.length ? cleanedValues.marketplace_id : undefined,
      partner_id: cleanedValues.partner_id?.length ? cleanedValues.partner_id : undefined,
    }

    setFilters(data)
    stockGetList({
      sorting: sorting,
      filters: data
    })
  }

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <Card style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 0.02)" }}>
        <Form form={form} onFinish={onSubmitFilter} name="analyticsFilter">
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="partner_id">
                <Select
                  mode={"multiple"}
                  placeholder="Партнеры"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                  defaultActiveFirstOption={false}
                >
                  {filterList?.partner_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="marketplace_id">
                <Select
                  placeholder="Маркетплейс"
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                      : false
                  }
                >
                  {(filterList?.marketplace_list || []).map((marketplace: any) => (
                    <Select.Option key={marketplace.id} value={marketplace.id}>
                      {marketplace.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status_id">
                <Select mode="multiple" placeholder="Статус склада"
                  showSearch
                  allowClear={true}
                  filterOption={(input, option) =>
                    option && option.children
                      ? (option.children as unknown as string)
                        .toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                  }
                >
                  {filterList?.status_list?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col span={24} style={{ textAlign: "left" }}>
              <Button type="primary" style={{ background: "#00a2e8" }} onClick={() => form.submit()}>
                                Применить
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => {
                  form.resetFields()
                  stockGetList({
                    sorting: sorting,
                    filters: defaultAnalyticsFilters,
                  })
                }}
              >
                                Сбросить
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  )
}

export default FilterTable
