import React, { FC } from "react"

import {
  AppstoreOutlined, AuditOutlined, CalendarOutlined, CarryOutOutlined,
  ContactsOutlined, FileSearchOutlined, FormOutlined, FundOutlined, InboxOutlined,
  ShoppingOutlined,
  TeamOutlined
} from "@ant-design/icons"
import { Menu } from "antd"
import { Link } from "react-router-dom"

import styles from "./Nav.module.scss"
import { MenuItem } from "../../../types/nav"
import {
  roles, SENIOR_MANAGER_CANCELLED, SENIOR_MANAGER_ROLE
} from "../../constants/roles"
import { routeNames } from "../../constants/routes"
import { useAllowedRoles } from "../../hooks/useAllowedRoles"
import { projectIcons } from "../../icons/projectIcons"
import {useTypedSelector} from "../../hooks/useTypedSelector";

const getItem = (
  label: React.ReactNode,
  key: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: (info: string) => void,
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    className: key?.includes("c") ? "children-list-item" : ""
  } as MenuItem
}

const Nav: FC = () => {
  const { role } = useTypedSelector((state) => state.currentUser)
  const isBuyerSadovod = role ===  roles.BUYER_SADOVOD
  const items: any = [
    // getItem(<Link to={routeNames.home}>Главная</Link>, "0", <DashboardOutlined />),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.MANAGER_ROLE,roles.SENIOR_MANAGER_CANCELLED ]) && getItem(
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        {projectIcons.productIcon()}<span >Товары</span>
      </div>,
      "6",
      <></>,
      [
        getItem(
          <Link to={routeNames.itemCards}>Карточки товаров</Link>,
          "c-7",
          <ShoppingOutlined />
        ),
        useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) ? getItem(
          <Link to={routeNames.importProducts}>
            <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
              {projectIcons.importIcon()}<span>Импорт товаров</span>
            </div>
          </Link>,
          "c-8"
        ) : null,
        useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) ? getItem(
          <Link to={routeNames.prices}>
            <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
              {projectIcons.priceIcon()}<span>Цены</span>
            </div>
          </Link>,
          "c-9"
        ) : null,
        useAllowedRoles([ roles.ADMIN_ROLE,roles.GENERAL_MANAGER ]) ? getItem(
          <Link to={routeNames.changeArticles}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {<FormOutlined />}<span>Смена артикулов</span>
            </div>
          </Link>,
          "c-9"
        ) : null
      ]
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.ASSEMBLER,roles.BUYER_SBERMARKET,roles.SENIOR_MANAGER_CANCELLED ]) && getItem(
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        <div>
          {projectIcons.productIcon()}
        </div>
        <span>Заказы на отгрузку</span>
      </div>,
      "13",
      <></>,
      [
        getItem(
          <Link style={{ lineHeight: "14px", whiteSpace: "break-spaces", position: "absolute", top: "10px" }} to={routeNames.stockFBS}>
                Со своего склада (FBS)
          </Link>,
          "c-1",
          <ShoppingOutlined />
        ),
        useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) ? getItem(
          <Link style={{ lineHeight: "14px", whiteSpace: "break-spaces", position: "absolute", top: "10px" }} to={routeNames.stockSuppliers}>
                Поставки
          </Link>,
          "c-2",
          <ShoppingOutlined />
        ) : null,
      ]
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) &&
    getItem(
      <Link to={routeNames.sbermarket}>Заказы СберМаркет</Link>,
      "12",
      <AppstoreOutlined />
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED,roles.BUYER_SADOVOD ]) && getItem(
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        {projectIcons.productIcon()}<span >Закупки</span>
      </div>,
      "14",
      <></>,
      [
        !isBuyerSadovod ? getItem(
          <Link className={"ph-20"} to={routeNames.suppliers}>Поставщики</Link>,
          "c-11",
          projectIcons.suppliersIcon()
        ) : null,
        getItem(
          <Link className={"ph-20"} to={routeNames.dropshipping}>Дропшиппинг</Link>,
          "c-3",
          <ShoppingOutlined />
        )
      ]
    ),

    useAllowedRoles([ roles.GENERAL_MANAGER,roles.COLLECTOR_SCANNER,roles.ASSEMBLER ]) && getItem(
      <div style={{ display: "flex", alignItems: "center" }}>
        {<CarryOutOutlined />}<span>Задания</span>
      </div>,
      "15",
      <></>,
      [
        getItem(
          <Link style={{ lineHeight: "14px", whiteSpace: "break-spaces", position: "absolute", top: "10px" }} to={routeNames.collectOrders}>
                Сборка заказов
          </Link>,
          "c-1",
          <InboxOutlined />
        )
      ]
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_CANCELLED, roles.SENIOR_MANAGER_ROLE, roles.GENERAL_MANAGER,roles.COLLECTOR_SCANNER,roles.ASSEMBLER ]) && getItem(
      <div style={{ display: "flex", alignItems: "center" }}>
        {<FundOutlined />}<span>Аналитика</span>
      </div>,
      "16",
      <></>,
      [
        getItem(
          <Link style={{ lineHeight: "14px", whiteSpace: "break-spaces", position: "absolute", top: "10px" }} to={routeNames.analytics}>
                Заказы за период
          </Link>,
          "c-1",
          <CalendarOutlined />
        )
      ]
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) &&
    getItem(
      <Link to={routeNames.partners}>Партнеры</Link>,
      "1",
      <ContactsOutlined />
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) &&
    getItem(
      <Link to={routeNames.staff}>Сотрудники</Link>,
      "2",
      <TeamOutlined />
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) &&
    getItem(
      <Link to={routeNames.monitoringMain}>Мониторинг</Link>,
      "4",
      <FileSearchOutlined />
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) && getItem(
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        {projectIcons.stockIcon()}<span>Склад</span>
      </div>,
      "7",
      <></>,
      [
        getItem(
          <Link to={routeNames.stock}>Склады</Link>,
          "c-5",
          <ContactsOutlined />
        ),
        ...([ useAllowedRoles([ roles.ADMIN_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) ?
          getItem(
            <Link to={routeNames.inventorization}>Инвентаризация</Link>,
            "c-6",
            <AuditOutlined />
          ) : null ])
      ]
    ),
    useAllowedRoles([ roles.SENIOR_MANAGER_ROLE,roles.GENERAL_MANAGER,roles.SENIOR_MANAGER_CANCELLED ]) &&
    getItem(
      <Link to={routeNames.export}>Экспорт</Link>,
      "6",
      projectIcons.TdesignFileExport()
    ),
  ].filter((item) => item !== false) as MenuItem[]

  return (
    <>
      <div className={styles.logoWrapper}>
        <Link to={routeNames.home}>CELLER CRM</Link>
      </div>
      <Menu theme="dark" mode="inline" items={items} selectable={false} />
    </>
  )
}
export default Nav
