import React, { FC, useEffect } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"

import { partnersApi } from "../../../common/api/endpoints/partnersApi"
import { stockApi } from "../../../common/api/endpoints/stockApi"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showErrorMessage, showFormErrors, showSuccessMessage } from "../../../utils/ui"

interface Props {
    isOpen: boolean
    setIsOpen: (v: boolean) => any
    editData: any
}

const WarehouseEditModal: FC<Props> = ({ isOpen, setIsOpen, editData }) => {
  const [ form ] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.partners)
  const { groupItems } = useTypedSelector((state) => state.stock)
  const { parentsEdit, parentsGetList } = useActions()
  const { stockEditGroup, stockGetGroupItems } = useActions()
  useEffect(() => {
    const fetchEditData = async () => {
      try {
        const { data } = await stockApi.fetchGroupById(editData.id)
        form.setFieldsValue({
          ...data,
          warehouse_ids:data?.selected_warehouses?.map((el:{id:string,name:string})=>({
            value:el.id,
            label:el.name
          }))
        })
      } catch (err) {
        showErrorMessage("Произошла ошибка во время получения данных.")
      }
    }
    fetchEditData()

  }, [ editData ])


  const updateGroup = (values: any) => {
    const data = {
      id: editData.id,
      ...values,
      warehouse_ids:values.warehouse_ids.map((el:{value:string})=>el.value),
    }

    stockEditGroup(
      data,
      () => {
        showSuccessMessage("Группа успешно обновлена.")
        setIsOpen(false)
        stockGetGroupItems()
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  return (
    <Modal
      title="Обновление группы склада"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Сохранить"
      cancelText="Отмена"
      okButtonProps={{
        loading: isLoading,
        className: "blueButton"
      }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={updateGroup}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="Название группы"
          name="name"
          rules={[ { required: true, message: "Поле обязательное" } ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Склады" name="warehouse_ids" rules={[ { required: true, message: "Поле обязательное" } ]}>
          <Select
            mode={"multiple"}
            placeholder="Склады"
            showSearch
            labelInValue
            filterOption={(input, option) =>
              option && option.children
                ? (option.children as unknown as string)
                  .toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
            defaultActiveFirstOption={false}
          >
            {groupItems?.warehouses?.map((item: any, index: number) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default WarehouseEditModal
