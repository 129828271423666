import React, { FC, useEffect } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"

import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { showFormErrors, showSuccessMessage } from "../../../utils/ui"

interface Props {
  isOpen: boolean
  setIsOpen: (v: boolean) => any
}
const WarehouseModal: FC<Props> = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm()

  const { isLoading } = useTypedSelector((state) => state.partners)
  const { groupItems } = useTypedSelector((state) => state.stock);
  const { stockAddGroupItems, stockGetGroupItems } = useActions()

  const addPartner = (values: any) => {
    stockAddGroupItems(
      values,
      () => {
        showSuccessMessage("Партнер успешно создан")
        setIsOpen(false)
        stockGetGroupItems()
      },
      (errors) => {
        showFormErrors(form, errors)
      }
    )
  }

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
    }
  }, [isOpen])

  return (
    <Modal
      title="Добавление группы склада"
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Добавить"
      cancelText="Отмена"
      okButtonProps={{
        loading: isLoading,
        className: "blueButton"
      }}
      destroyOnClose
    >
      <Form
        className="un-form__wrapper"
        form={form}
        onFinish={addPartner}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          label="Название группы"
          name="group_name"
          rules={[{ required: true, message: "Поле обязательное" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Склады" name="warehouse_ids" rules={[{ required: true, message: "Поле обязательное" }]}>
          <Select
            mode={"multiple"}
            placeholder="Склады"
            showSearch
            filterOption={(input, option) =>
              option && option.children
                ? (option.children as unknown as string)
                  .toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
            defaultActiveFirstOption={false}
          >
            {groupItems?.warehouses?.map((item: any, index: number) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default WarehouseModal
