import { StockSliceActions } from "./stockSlice"
import { stockApi } from "../../../common/api/endpoints/stockApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const stockActions = {
  ...StockSliceActions,
  inventarizationGetList: (isSocket = false) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchInventarization()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setInventoriesData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(stockActions.setStockLoading(false))
      })
  },
  stockGetList: (filters: any) => (dispatch: TAppDispatch) => {
    dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setStockData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockActions.setStockLoading(false))
      })
  },

  stockGetFilterList: () => (dispatch: TAppDispatch) => {
    dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchFilterList()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setStockFilters(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockActions.setStockLoading(false))
      })
  },
  stockGetGroupItems: () => (dispatch: TAppDispatch) => {
    dispatch(stockActions.setStockLoading(true))

    stockApi
      .fetchGroupItems()
      .then((response) => {
        if (response.status === 200) {
          dispatch(stockActions.setStockGroupItems(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockActions.setStockLoading(false))
      })
  },

  stockAddGroupItems: (group: any, onFinish: () => void, onFormError: (errors: { [key: string]: string }) => void) => (dispatch: TAppDispatch) => {
    dispatch(stockActions.setStockLoading(true))

    stockApi
      .addGroupItems(group)
      .then((response) => {
        if (response.status === 200) {
          // dispatch(stockActions.setStockGroupItems(response.data))
          onFinish && onFinish()
        }
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          onFormError(err.response.data)
        }
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(stockActions.setStockLoading(false))
      })
  },

  stockCreate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .create(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      },

  stockEdit:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .edit(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      },

  stockDelete:
    (id: string | number, onFinish?: () => void) =>
      (dispatch: TAppDispatch) => {
        dispatch(stockActions.setStockLoading(true))

        stockApi
          .delete(id)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(stockActions.setStockLoading(false))
          })
      },

      stockDeleteGroup:
      (id: string | number, onFinish?: () => void) =>
        (dispatch: TAppDispatch) => {
          dispatch(stockActions.setStockLoading(true))
  
          stockApi
            .deleteGroupItems(id)
            .then((response) => {
              if (response.status === 200) {
                onFinish && onFinish()
              }
            })
            .catch((err) => {
              handleResponseError(dispatch, err)
            })
            .finally(() => {
              dispatch(stockActions.setStockLoading(false))
            })
        },
        
        stockEditGroup:
        (
          data: any,
          onFinish: () => void,
          onFormError: (errors: { [key: string]: string }) => void
        ) =>
          (dispatch: TAppDispatch) => {
            dispatch(stockActions.setStockLoading(true))
    
            stockApi
              .groupEdit(data)
              .then((response) => {
                if (response.status === 200) {
                  onFinish && onFinish()
                }
              })
              .catch((err) => {
                if (err.response?.status === 400) {
                  onFormError(err.response.data)
                }
                handleResponseError(dispatch, err)
              })
              .finally(() => {
                dispatch(stockActions.setStockLoading(false))
              })
          },        
}
