import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IFilterList, IGroupItems, Inventorization, IPartnerRow, IPartnersState} from "./types"

const initialState: IPartnersState = {
  data: [],
  filterList: {
    partner_list: [],
    status_list: [],
    marketplace_list: []
  },
  groupItems: {
    // group_id: 0,
    // group_name: '',
    // warehouse_names: '',
    items: [],
    warehouses: []
  },
  inventoriesData: null,
  isLoading: false,
  isUpdate: false
}

export const stockSlice = createSlice({
  name: "stock",
  initialState: initialState,
  reducers: {
    setStockData: (state, action: PayloadAction<IPartnerRow[]>) => {
      state.data = action.payload
    },
    setStockLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setStockUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setInventoriesData:(state, action: PayloadAction<Inventorization>) => {
      state.inventoriesData = action.payload
    },
    setStockFilters:(state, action: PayloadAction<IFilterList>) => {
      state.filterList = action.payload
    },
    setStockGroupItems:(state, action: PayloadAction<IGroupItems>) => {
      state.groupItems = action.payload
    }
  }
})

export const StockSliceReducer = stockSlice.reducer
export const StockSliceActions = stockSlice.actions
