import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IItemState, IItemRow, Articles } from "./types"

const initialState: IItemState = {
  itemData: [],
  pagesData: {
    total: 2,
    totalPages: 1,
    start: 1,
    end: 20,
    currentPage: 1,
    pageSize: 20
  },
  isPricingLoading: false,
  isSuppliersLoading: false,
  lists: {},
  isLoading: false,
  isUpdate: false,
  isLoadingLists: false,
  bannerData: null,
  articlesData: null,
}

export const itemSlice = createSlice({
  name: "item",
  initialState: initialState,
  reducers: {
    setItemData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload.map((el)=>({...el,key:el.id}))
    },
    setItemCardData: (state, action: PayloadAction<IItemRow[]>) => {
      state.itemData = action.payload.map((el)=>({...el,key:el.id}))
    },
    setItemBannerData: (state, action: PayloadAction<any>) => {
      state.bannerData = action.payload
    },
    setItemPagesData: (state, action: PayloadAction<any>) => {
      state.pagesData = action.payload
    },
    setItemLists: (state, action: PayloadAction<any>) => {
      state.lists = action.payload
    },
    setItemListsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLists = action.payload
    },
    setItemLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPricingLoading: (state, action: PayloadAction<boolean>) => {
      state.isPricingLoading = action.payload
    },
    setSuppliersLoading: (state, action: PayloadAction<boolean>) => {
      state.isSuppliersLoading = action.payload
    },
    setItemUpdate: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setArticlesData:(state, action: PayloadAction<Articles>) => {
          state.articlesData = action.payload
    },
  }
})

export const itemSliceReducer = itemSlice.reducer
export const itemSliceActions = itemSlice.actions
